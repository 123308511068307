.footer-section {

  background-color: #dddee0;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
    svg {
      width: 120px;
      height: 90px;
      margin: 0;
      padding: 0;
    }

    &-contact {

        display: flex;
        flex-direction: column;
        align-items: center;

        form {
            @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            #mc_embed_signup_scroll {
                @media (max-width: 767px) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .mc-field-group {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                }

                @media (min-width: 768px) {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }

            }

            label {
                margin: 10px;
                color: #52575f;
                font-size: 14px;
                text-transform: uppercase;
            }

            input {
                width: 290px;
                border: 1px solid rgb(110, 110, 110);
                padding: 6px 5px;
            }

            input::placeholder {
                color: fade-out(black, 0.2);
            }

            .clear {
                // @media (max-width: 767px) {
                //     margin-top: 10px;
                // }

                // @media (min-width: 768px) {
                //     margin-left: 10px;
                // }
            }

            .submit-button {
                width: 152px;
                margin: 10px;
                padding: 6px 10px;
                background-color: #52575f;
                border: 1px solid black;
                color: white;
                text-transform: uppercase;
                &:hover {
                  background-color: white;
                  color: black;
                  cursor: pointer;
                }
            }
        }
    }

    &-legal {
        padding: 60px 0px 20px 0px;
        text-transform: uppercase;
        font-size: 10px;

        @media (min-width: 480px) {
            font-size: 12px;
        }
    }
}
