.instagram-section {
  margin: 0px auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-wrapper {
      display: block;

      @media (min-width: 480px) {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
      }
  }

  &-item {
      flex: 1;
      flex-basis: 25%;
      padding: 10px;

      img {
          width: 100%;
      }
  }

  &-load-more-button {
      margin: 20px 0px 30px;
      font-family: "Montserrat-Light";
      border: 1px solid #52575f;
      padding: 8px 15px;
      transition: background-color 600ms;

      &:hover {
          background-color: #dddee0;
      }
  }
}
