.image-collage {

  $gutter: 15px;
  padding: 7px;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &-wrapper {
    margin: 0px auto;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    margin-bottom: 15px;

    @media (min-width: 768px) {
      height: 60vw;
    }

    @media (min-width: 1600px) {
      max-width: 1200px;
      max-height: 720px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @media (max-width: 767px) {
      margin: $gutter / 2 0px;
    }
  }

  @media (min-width: 768px) {
    &-left,
    &-right {
      position: absolute;
    }

    &-left {
      top: 0px;
      left: 0px;
      width: calc(40% - #{$gutter / 2});
      height: 100%;
    }

    &-right {
      top: 0px;
      right: 0px;
      width: calc(60% - #{$gutter / 2});
      height: 100%;

      &-top,
      &-bottom {
        position: absolute;
      }

      &-top {
        top: 0px;
        left: 0px;
        width: 100%;
        height: calc(66.67% - #{$gutter / 2});
      }

      &-bottom {
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: calc(33.33% - #{$gutter / 2});

        &-left,
        &-right {
          position: absolute;
          top: 0px;
        }

        &-left {
          left: 0px;
          width: calc(50% - #{$gutter / 2});
          height: 100%;
        }

        &-right {
          right: 0px;
          width: calc(50% - #{$gutter / 2});
          height: 100%;
        }
      }
    }
  }
}


.image-collage-three {

  $gutter: 15px;
  padding: $gutter;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &-wrapper {
    margin: 0px auto;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    margin-bottom: 15px;

    @media (min-width: 768px) {
      height: 60vw;
    }

    @media (min-width: 1600px) {
      max-width: 1200px;
      max-height: 720px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @media (max-width: 767px) {
      margin: $gutter / 2 0px;
    }
  }

  @media (min-width: 768px) {

    &-left,
    &-right {
      position: absolute;
      width: calc(50% - #{$gutter / 2});
      height: 100%;
    }

    &-right {
      top: 0px;
      right: 0px;
    }

    &-left {
      left: 0px;

      &-top,
      &-bottom {
        position: absolute;
        left: 0px;
        width: 100%;
        height: calc(50% - #{$gutter / 2});
      }

      &-top {
        top: 0px;
      }

      &-bottom {
        bottom: 0px;
      }
    }    
  }
}





.image-collage-three-swapped {

  $gutter: 15px;
  padding: $gutter;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &-wrapper {
    margin: 0px auto;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    margin-bottom: 15px;

    @media (min-width: 768px) {
      height: 60vw;
    }

    @media (min-width: 1600px) {
      max-width: 1200px;
      max-height: 720px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @media (max-width: 767px) {
      margin: $gutter / 2 0px;
    }
  }

  @media (min-width: 768px) {

    &-left,
    &-right {
      position: absolute;
      width: calc(50% - #{$gutter / 2});
      height: 100%;
    }

    &-left {
      top: 0px;
      left: 0px;
    }

    &-right {
      right: 0px;

      &-top,
      &-bottom {
        position: absolute;
        left: 0px;
        width: 100%;
        height: calc(50% - #{$gutter / 2});
      }

      &-top {
        top: 0px;
      }

      &-bottom {
        bottom: 0px;
      }
    }    
  }
}





.image-collage-two {

  $gutter: 15px;
  padding: $gutter;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &-wrapper {
    margin: 0px auto;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;

    @media (min-width: 768px) {
      height: 60vw;
    }

    @media (min-width: 1600px) {
      max-width: 1200px;
      max-height: 720px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @media (max-width: 767px) {
      margin: $gutter / 2 0px;
    }
  }

  @media (min-width: 768px) {

    &-left,
    &-right {
      position: absolute;
      height: 100%;
      top: 0;
    }

    &-left {
      width: calc(50% - #{$gutter / 2});
      left: 0px;
    }

    &-right {
      width: calc(50% - #{$gutter / 2});        
      right: 0px;
    }    
  }
}



.image-collage-two-half {

  $gutter: 15px;
  padding: $gutter;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &-wrapper {
    margin: 0px auto;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;

    @media (min-width: 768px) {
      height: 30vw;
    }

    @media (min-width: 1600px) {
      max-width: 1200px;
      max-height: 360px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @media (max-width: 767px) {
      margin: $gutter / 2 0px;
    }
  }

  @media (min-width: 768px) {

    &-left,
    &-right {
      position: absolute;
      height: 100%;
      top: 0;
    }

    &-left {
      width: calc(50% - #{$gutter / 2});
      left: 0px;
    }

    &-right {
      width: calc(50% - #{$gutter / 2});        
      right: 0px;
    }    
  }
}
