.slice-side-text {
  margin: 40px; }
  .slice-side-text h2 {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 4px;
    text-transform: uppercase; }
  .slice-side-text h4 {
    font-size: 14px;
    line-height: 1.2;
    margin-top: 3px;
    font-family: "Muli";
    font-weight: 400; }
  .slice-side-text h5 {
    border: 1px solid black;
    display: inline-block;
    padding: 15px 20px;
    text-transform: uppercase;
    font-size: 11px; }
  .slice-side-text a {
    text-decoration: none;
    color: unset;
    text-transform: uppercase; }
  .slice-side-text a[href="/"] {
    cursor: unset;
    pointer-events: none; }

.slice-side-text .caption {
  text-align: center;
  z-index: 100;
  background-color: white;
  padding: 14px;
  display: block;
  width: auto; }
  @media (min-width: 768px) {
    .slice-side-text .caption {
      position: absolute;
      width: 200px; } }

@media (min-width: 768px) {
  .slice-side-text-topleft .caption {
    top: 30px;
    left: 30px; }
  .slice-side-text-topcenter .caption {
    top: 30px;
    left: 0;
    right: 0;
    margin: auto; }
  .slice-side-text-topright .caption {
    top: 30px;
    right: 30px; }
  .slice-side-text-middleleft .caption {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 30px; }
  .slice-side-text-middlecenter .caption {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto; }
  .slice-side-text-middleright .caption {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 30px; }
  .slice-side-text-bottomleft .caption {
    bottom: 30px;
    left: 30px; }
  .slice-side-text-bottomcenter .caption {
    bottom: 30px;
    left: 0;
    right: 0;
    margin: auto; }
  .slice-side-text-bottomright .caption {
    bottom: 30px;
    right: 30px; } }

.image-collage {
  padding: 7px;
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  .image-collage-wrapper {
    margin: 0px auto;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      .image-collage-wrapper {
        height: 60vw; } }
    @media (min-width: 1600px) {
      .image-collage-wrapper {
        max-width: 1200px;
        max-height: 720px; } }
  .image-collage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
    @media (max-width: 767px) {
      .image-collage img {
        margin: 7.5px 0px; } }
  @media (min-width: 768px) {
    .image-collage-left, .image-collage-right {
      position: absolute; }
    .image-collage-left {
      top: 0px;
      left: 0px;
      width: calc(40% - 7.5px);
      height: 100%; }
    .image-collage-right {
      top: 0px;
      right: 0px;
      width: calc(60% - 7.5px);
      height: 100%; }
      .image-collage-right-top, .image-collage-right-bottom {
        position: absolute; }
      .image-collage-right-top {
        top: 0px;
        left: 0px;
        width: 100%;
        height: calc(66.67% - 7.5px); }
      .image-collage-right-bottom {
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: calc(33.33% - 7.5px); }
        .image-collage-right-bottom-left, .image-collage-right-bottom-right {
          position: absolute;
          top: 0px; }
        .image-collage-right-bottom-left {
          left: 0px;
          width: calc(50% - 7.5px);
          height: 100%; }
        .image-collage-right-bottom-right {
          right: 0px;
          width: calc(50% - 7.5px);
          height: 100%; } }

.image-collage-three {
  padding: 15px;
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  .image-collage-three-wrapper {
    margin: 0px auto;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      .image-collage-three-wrapper {
        height: 60vw; } }
    @media (min-width: 1600px) {
      .image-collage-three-wrapper {
        max-width: 1200px;
        max-height: 720px; } }
  .image-collage-three img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
    @media (max-width: 767px) {
      .image-collage-three img {
        margin: 7.5px 0px; } }
  @media (min-width: 768px) {
    .image-collage-three-left, .image-collage-three-right {
      position: absolute;
      width: calc(50% - 7.5px);
      height: 100%; }
    .image-collage-three-right {
      top: 0px;
      right: 0px; }
    .image-collage-three-left {
      left: 0px; }
      .image-collage-three-left-top, .image-collage-three-left-bottom {
        position: absolute;
        left: 0px;
        width: 100%;
        height: calc(50% - 7.5px); }
      .image-collage-three-left-top {
        top: 0px; }
      .image-collage-three-left-bottom {
        bottom: 0px; } }

.image-collage-three-swapped {
  padding: 15px;
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  .image-collage-three-swapped-wrapper {
    margin: 0px auto;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      .image-collage-three-swapped-wrapper {
        height: 60vw; } }
    @media (min-width: 1600px) {
      .image-collage-three-swapped-wrapper {
        max-width: 1200px;
        max-height: 720px; } }
  .image-collage-three-swapped img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
    @media (max-width: 767px) {
      .image-collage-three-swapped img {
        margin: 7.5px 0px; } }
  @media (min-width: 768px) {
    .image-collage-three-swapped-left, .image-collage-three-swapped-right {
      position: absolute;
      width: calc(50% - 7.5px);
      height: 100%; }
    .image-collage-three-swapped-left {
      top: 0px;
      left: 0px; }
    .image-collage-three-swapped-right {
      right: 0px; }
      .image-collage-three-swapped-right-top, .image-collage-three-swapped-right-bottom {
        position: absolute;
        left: 0px;
        width: 100%;
        height: calc(50% - 7.5px); }
      .image-collage-three-swapped-right-top {
        top: 0px; }
      .image-collage-three-swapped-right-bottom {
        bottom: 0px; } }

.image-collage-two {
  padding: 15px;
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  .image-collage-two-wrapper {
    margin: 0px auto;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto; }
    @media (min-width: 768px) {
      .image-collage-two-wrapper {
        height: 60vw; } }
    @media (min-width: 1600px) {
      .image-collage-two-wrapper {
        max-width: 1200px;
        max-height: 720px; } }
  .image-collage-two img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
    @media (max-width: 767px) {
      .image-collage-two img {
        margin: 7.5px 0px; } }
  @media (min-width: 768px) {
    .image-collage-two-left, .image-collage-two-right {
      position: absolute;
      height: 100%;
      top: 0; }
    .image-collage-two-left {
      width: calc(50% - 7.5px);
      left: 0px; }
    .image-collage-two-right {
      width: calc(50% - 7.5px);
      right: 0px; } }

.image-collage-two-half {
  padding: 15px;
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  .image-collage-two-half-wrapper {
    margin: 0px auto;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto; }
    @media (min-width: 768px) {
      .image-collage-two-half-wrapper {
        height: 30vw; } }
    @media (min-width: 1600px) {
      .image-collage-two-half-wrapper {
        max-width: 1200px;
        max-height: 360px; } }
  .image-collage-two-half img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
    @media (max-width: 767px) {
      .image-collage-two-half img {
        margin: 7.5px 0px; } }
  @media (min-width: 768px) {
    .image-collage-two-half-left, .image-collage-two-half-right {
      position: absolute;
      height: 100%;
      top: 0; }
    .image-collage-two-half-left {
      width: calc(50% - 7.5px);
      left: 0px; }
    .image-collage-two-half-right {
      width: calc(50% - 7.5px);
      right: 0px; } }

.instagram-section {
  margin: 0px auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .instagram-section-wrapper {
    display: block; }
    @media (min-width: 480px) {
      .instagram-section-wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between; } }
  .instagram-section-item {
    flex: 1 1;
    flex-basis: 25%;
    padding: 10px; }
    .instagram-section-item img {
      width: 100%; }
  .instagram-section-load-more-button {
    margin: 20px 0px 30px;
    font-family: "Montserrat-Light";
    border: 1px solid #52575f;
    padding: 8px 15px;
    -webkit-transition: background-color 600ms;
    transition: background-color 600ms; }
    .instagram-section-load-more-button:hover {
      background-color: #dddee0; }

.footer-section {
  background-color: #dddee0;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .footer-section svg {
    width: 120px;
    height: 90px;
    margin: 0;
    padding: 0; }
  .footer-section-contact {
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 768px) {
      .footer-section-contact form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; } }
    @media (max-width: 767px) {
      .footer-section-contact form #mc_embed_signup_scroll {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .footer-section-contact form #mc_embed_signup_scroll .mc-field-group {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; } }
    @media (min-width: 768px) {
      .footer-section-contact form #mc_embed_signup_scroll {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; } }
    .footer-section-contact form label {
      margin: 10px;
      color: #52575f;
      font-size: 14px;
      text-transform: uppercase; }
    .footer-section-contact form input {
      width: 290px;
      border: 1px solid #6e6e6e;
      padding: 6px 5px; }
    .footer-section-contact form input::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.8); }
    .footer-section-contact form input::-moz-placeholder {
      color: rgba(0, 0, 0, 0.8); }
    .footer-section-contact form input::-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.8); }
    .footer-section-contact form input::placeholder {
      color: rgba(0, 0, 0, 0.8); }
    .footer-section-contact form .submit-button {
      width: 152px;
      margin: 10px;
      padding: 6px 10px;
      background-color: #52575f;
      border: 1px solid black;
      color: white;
      text-transform: uppercase; }
      .footer-section-contact form .submit-button:hover {
        background-color: white;
        color: black;
        cursor: pointer; }
  .footer-section-legal {
    padding: 60px 0px 20px 0px;
    text-transform: uppercase;
    font-size: 10px; }
    @media (min-width: 480px) {
      .footer-section-legal {
        font-size: 12px; } }

body {
  margin: 0;
  padding: 0;
}

