.slice-side-text {
  margin: 40px;

  h2 {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  h4 {
    font-size: 14px;
    line-height: 1.2;
    margin-top: 3px;
    font-family: "Muli";
    font-weight: 400;
  }

  h5 {
    border: 1px solid black;
    display: inline-block;
    padding: 15px 20px;
    text-transform: uppercase;
    font-size: 11px;
  }

  a {
    text-decoration: none;
    color: unset;
    text-transform: uppercase;
  }

  a[href="/"] {
    cursor: unset;
    pointer-events: none;
  }
}

.slice-side-text .caption {
  text-align: center;
  z-index: 100;
  background-color: white;
  padding: 14px;
  display: block;
  width: auto;
  
  @media (min-width: 768px) {
    position: absolute;
    width: 200px;
  }

}

@media (min-width: 768px) {

  .slice-side-text-topleft .caption {
    top: 30px;
    left: 30px;
  }
  .slice-side-text-topcenter .caption {
    top: 30px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .slice-side-text-topright .caption {
    top: 30px;
    right: 30px;
  }

  .slice-side-text-middleleft .caption {
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
  }

  .slice-side-text-middlecenter .caption {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
  }
  .slice-side-text-middleright .caption {
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
  }

  .slice-side-text-bottomleft .caption {
    bottom: 30px;
    left: 30px;
  }
  .slice-side-text-bottomcenter .caption {
    bottom: 30px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .slice-side-text-bottomright .caption {
    bottom: 30px;
    right: 30px;
  }

}
